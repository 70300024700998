import { Box, VStack } from "@chakra-ui/react";
import { CustomRendererProps } from "./customRenderers";
import MessageContent from "../MessageContent";
import FeedbackActions from "./FeedbackActions";
import Lottie from "react-lottie";
import * as animationData from './loading-animation.json'

const WeInsureCS: React.FC<CustomRendererProps> = ({ message, onFeedback, onShowSources, streamRunning }) => {

  return (
    <VStack align="start" width="100%" id="ask-vstack" >
      <MessageContent message={message} />
      {
        streamRunning && (
          <Box>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={40}
              width={45} />
          </Box>
        )
      }
      {
        !streamRunning && (
          <FeedbackActions runId={message.runId} onFeedback={onFeedback} />
        )
      }
    </VStack>
  )
}


export default WeInsureCS;