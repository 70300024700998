import React, { useMemo } from "react";
import { AssistantFile, AssistantFileStatus } from "../../../types";
import { Box, CircularProgress, HStack, Icon, IconButton, Spinner, Text, Tooltip, useColorModeValue, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { MdDelete, MdDescription, MdError, MdFolder } from "react-icons/md";
import { useFileManager } from "./FileManagerProvider";
import EmptyDropZone from "./EmptyDropZone";
import { useDialog } from "../../DialogProvider";
import { useAssistantFiles } from "../../../state/assistantFiles";


interface Props {
  assistantId: string;
  currentPath: string;
  items: AssistantFile[];
}

const CurrentFolder: React.FC<Props> = ({ items, assistantId, currentPath }) => {
  const dialog = useDialog();
  const fileManager = useFileManager();
  const assistantFiles = useAssistantFiles();
  const subFolders = useMemo(() => items.filter((child) => child.type === 'folder'), [ items ]);
  const files = useMemo(() => items.filter((child) => child.type === 'file'), [ items ]);
  const hasSubFolders = subFolders.length > 0;

  const subFolderBgColor = useColorModeValue("white", "gray.900");
  const subFolderBorderColor = useColorModeValue("gray.200", "gray.700");

  const onDeletFile = (path: string) => {
    dialog.confirm({
      title: 'Delete file?',
      subtitle: path,
      onConfirm: () => {
        console.log('Delete file', path);
        assistantFiles.deleteFile(assistantId, path, currentPath);
      }
    });
  }

  return (
    <VStack align="start">
      {
        hasSubFolders && (
          <VStack align="start" p={3}>
            <Text fontWeight="600">Folders</Text>
            <Wrap spacing={5} align="start">
              {
                subFolders.map((subFolder, index) => {
                  const id = subFolder.path
                  const width = 150;
                  const isDropping = fileManager.activeDropZone === id && fileManager.activeDragItem !== id;

                  return (
                    <WrapItem key={index}>
                      <VStack
                        data-path={id}
                        position="relative"
                        data-dir="true"
                        align="start"
                        width={`${width}px`}
                        borderRadius={10}
                        bgColor={isDropping ? "primary.500" : subFolderBgColor}
                        color={isDropping ? "black" : "inherit"}
                        borderColor={subFolderBorderColor}
                        borderWidth={1}
                        px={4}
                        py={2}
                        flexShrink={0}
                        cursor="pointer"
                        onClick={() => fileManager.setCurrentPath(id)}
                      >
                        <Icon as={MdFolder} pointerEvents="none" boxSize="30px" color="gray.500" />
                        <Text
                          flexShrink={0}
                          fontSize={15}
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          width={`${width - 20}px`}
                          pointerEvents="none"
                        >{subFolder.name}</Text>

                        {
                          subFolder.status === 'uploading' && (
                            <CircularProgress
                              isIndeterminate
                              size={6}
                              position="absolute"
                              top="5px"
                              right="5px"
                            />
                          )
                        }

                        {
                          subFolder.status === 'indexing' && (
                            <Spinner
                              // size="10px"
                              position="absolute"
                              top="5px"
                              right="5px"
                            />
                          )
                        }

                        {
                          subFolder.status === 'error' && (
                            <Box
                              position="absolute"
                              top="5px"
                              right="5px"
                            >
                              <Tooltip label="One or more errors">
                                <span>
                                  <Icon as={MdError} color="red.500" fontSize="22px" />
                                </span>
                              </Tooltip>
                            </Box>
                          )
                        }


                      </VStack>
                    </WrapItem>
                  )
                })
              }
            </Wrap>
          </VStack>
        )
      }

      {
        files.length === 0 && !fileManager.managedBy && (
          <EmptyDropZone path={fileManager.currentPath} />
        )
      }
      {
        files.length > 0 && (
          <Box
            width="100%"
            position="relative"
          >
            <VStack
              align="start"
              width="100%"
              p={3}
            >
              <Box
                m={1}
                position="absolute"
                top="0px"
                left="0px"
                right="0px"
                bottom="0px"
                borderWidth="2px"
                borderColor={fileManager.activeDropZone === fileManager.currentPath ? "primary.500" : "transparent"}
                borderStyle="dashed"
                borderRadius={10}
                data-path={fileManager.currentPath}
                data-dir="true"
                data-dropzone="true"
                {...fileManager.getDropZoneProps()}
              />
              <Text fontWeight="600" pointerEvents="none">Files</Text>
              {
                files.map((child, index) => {
                  return (
                    <FileItem key={index} file={child} onDelete={onDeletFile} />
                  );
                })
              }
            </VStack>
          </Box>
        )
      }
    </VStack>
  );
};

export default CurrentFolder;

interface FileItemProps {
  file: AssistantFile;
  onDelete: (path: string) => void;
}

const FileItem: React.FC<FileItemProps> = ({ file, onDelete }) => {
  const fileManager = useFileManager();
  return (
    <HStack
      px={4}
      py={2}
      borderRadius={10}
      borderColor={useColorModeValue("gray.200", "gray.700")}
      bgColor={useColorModeValue("white", "gray.900")}
      borderWidth={1}
      width="100%"
      data-path={fileManager.currentPath}
      data-dir="true"
      data-dropzone="true"
      {...fileManager.getDropZoneProps()}
    >
      <Icon as={MdDescription} pointerEvents={fileManager.activeDragItem ? 'none' : 'auto'} />
      <Text pointerEvents='none'>{file.name}</Text>
      <Box flexGrow={1} pointerEvents="none" />
      {
        file.status === AssistantFileStatus.uploading && (
          <CircularProgress size={6} value={file.progress} />
        )
      }

      {
        file.status === AssistantFileStatus.indexing && (
          <HStack>
            <Text fontSize="12px">INDEXING</Text>
            <Spinner />
          </HStack>
        )
      }

      {
        file.status === AssistantFileStatus.error && (
          <Box zIndex={999}>
            <Tooltip label={file.error}>
              <span>
                <Icon as={MdError} color="red.500" fontSize="22px" />
              </span>
            </Tooltip>
          </Box>
        )
      }

      {
        file.status !== AssistantFileStatus.uploading && (
          <IconButton
            icon={<Icon as={MdDelete} />}
            aria-label="Delete file"
            variant="ghost"
            borderRadius={99}
            size="sm"
            onClick={() => onDelete(file.path)}
            isDisabled={!fileManager.hasFilePermission}
          />
        )
      }

    </HStack>
  );
}