import { Box, Button, HStack, Icon, useClipboard } from "@chakra-ui/react"
import { Message } from "../../../../gen-ts/ai/assistants/v0/assistant_pb";
import { useAppToast } from "../../../../hooks/useAppToast";
import { MdContentCopy, MdLanguage } from "react-icons/md";
import { getSkin } from "../../../../skins";
import LocalStorage from "../../../../services/localStorage";
import { useChats } from "../../../../state/chats";
import { useAssistants } from "../../../../state/assistants";
import { useDebugDialog } from "../../../DebugDialogProvider";
import { CgDebug } from "react-icons/cg";

interface AssistantMessageActionsProps {
  message: Message;
  streamRunning: boolean;
  assistantId: string;
  onShowSources: (runId: string, filters?: string[]) => void;
}


const getMessageCopyText = (message: Message) => {
  return message.content.map(c => c.content.value).join('\n');
}

const AssistantMessafeActions: React.FC<AssistantMessageActionsProps> = ({ message, streamRunning, assistantId, onShowSources }) => {
  const toast = useAppToast();
  const chatsState = useChats();
  const assistantsState = useAssistants();
  const debugDialog = useDebugDialog();
  const assistant = assistantsState.assistants[ assistantId ];

  const run = chatsState.runs[ message.runId ];

  const { onCopy } = useClipboard(getMessageCopyText(message));


  const handleOnCopy = () => {
    onCopy();
    toast({
      message: 'Copied to clipboard.',
      variant: 'success',
    });
  }

  return (
    <HStack justify="start" spacing={3}>
      {
        (!getSkin().assistantId || LocalStorage.getIsDebugMode()) && (
          <Button
            isLoading={chatsState.runLoading[ message.runId ]}
            onClick={async () => {
              const run = await chatsState.loadRunById(message.runId);
              const runTime = run?.endTime && run?.startTime ? run.endTime.seconds - run.startTime.seconds : 0;
              debugDialog.openDialog({
                'Run ID': message.runId,
                "Run Duration": `${runTime.toString()} seconds`,
              });
            }}
            size="xs"
            variant="ghost"
            leftIcon={<Icon as={CgDebug} fontSize="13px" />}
          >
            Debug
          </Button>
        )
      }

      {
        !getSkin().assistantId && (
          <Button
            visibility={streamRunning ? 'hidden' : 'visible'}
            onClick={handleOnCopy}
            size="xs"
            variant="ghost"
            leftIcon={<Icon as={MdContentCopy} fontSize="13px" />}
          >
            Copy
          </Button>
        )
      }


      {
        !assistant?.citationsEnabled && (
          <Button
            visibility={streamRunning ? 'hidden' : 'visible'}
            size="xs"
            variant="ghost"
            leftIcon={<Icon as={MdLanguage}
              fontSize="13px" />}
            onClick={() => {
              onShowSources(message.runId);
            }}
          >
            Sources {
              run && (
                (`(${run.contextDocuments.length})`)
              )
            }
          </Button>
        )
      }
    </HStack>
  )
}

export default AssistantMessafeActions;