import { Box, HStack, useColorModeValue } from "@chakra-ui/react";

import { useMeasure } from "react-use";
import SubRunCard from "./SubRunCard";
import { useEffect, useRef, useState } from "react";

interface SubRunsProps {
  childRunIds: string[];
  streamRunning: boolean;
}

const SubRuns: React.FC<SubRunsProps> = ({ childRunIds, streamRunning }) => {
  const [ ref, { width } ] = useMeasure();

  const containerRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLDivElement>(null);

  const [ canScrollRight, setCanScrollRight ] = useState(false);
  const [ canScrollLeft, setCanScrollLeft ] = useState(false);


  useEffect(() => {
    if (containerRef.current) {
      setCanScrollLeft(containerRef.current.scrollLeft > 0);
      setCanScrollRight(containerRef.current.scrollLeft < containerRef.current.scrollWidth - containerRef.current.clientWidth);
    }

    //eslint-disable-next-line
  }, [ containerRef.current ]);


  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setCanScrollLeft(e.currentTarget.scrollLeft > 0);
    setCanScrollRight(e.currentTarget.scrollLeft < e.currentTarget.scrollWidth - e.currentTarget.clientWidth);
  }



  const scrollCoverWidth = width / 10;

  const overflowColor = useColorModeValue('white', 'gray.800');

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Box ref={ref as any} sx={{ width: '100%' }} />
      <HStack
        ref={containerRef}
        sx={{
          maxWidth: `${width}px`,
          minHeight: '100px',
          overflow: 'scroll',
          width: '100%',
          position: 'relative',
        }}
        spacing={3}
        onScroll={onScroll}
      >
        {childRunIds.map((childRunId) => (
          <SubRunCard key={childRunId} subRunId={childRunId} streamRunning={streamRunning} />
        ))}
      </HStack>

      <Box
        ref={rightRef}
        sx={{
          pointerEvents: 'none',
          transition: 'opacity .3s ease-in-out',
          opacity: canScrollRight ? 1 : 0,
          position: 'absolute',
          top: 0,
          right: 0,
          width: `${scrollCoverWidth}px`,
          height: '100%',
        }}
        bgGradient={`linear(to-r, transparent, ${overflowColor})`}
      />
      <Box
        ref={leftRef}
        sx={{
          pointerEvents: 'none',
          transition: 'opacity .3s ease-in-out',
          opacity: canScrollLeft ? 1 : 0,
          position: 'absolute',
          top: 0,
          left: 0,
          width: `${scrollCoverWidth}px`,
          height: '100%',
        }}
        bgGradient={`linear(to-l, transparent, ${overflowColor})`}
      />
    </Box>
  );
}

export default SubRuns;