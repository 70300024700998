import { Message } from "../../../../gen-ts/ai/assistants/v0/assistant_pb";
import AskScendRenderer from "./AskAscend";
import AskScendStreamingRenderer from "./AskAscendStreaming";
import WeInsureCS from "./WeInsureCS";



export interface CustomRendererProps {
  message: Message;
  onFeedback: (runId: string) => void;
  onShowSources: (runId: string, filters?: string[]) => void;
  streamRunning: boolean;
}
export const customRenderers: Record<string, React.FC<CustomRendererProps>> = {
  'ASSISTANT_4635ZEG88M09MBVJW2X4N4Q4R1': AskScendRenderer,
  'ASSISTANT_6NKGF3QMF1A652CFTENBKMD501': AskScendStreamingRenderer,
  'ASSISTANT_7FPVS4PMY788WW88X0SK3E54G1': WeInsureCS,
  'ASSISTANT_1E07ED53HEYB5V8R25B63MF501': AskScendStreamingRenderer,
}
