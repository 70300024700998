import { Box, HStack, IconButton, Portal, Skeleton, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import MessageContent from "./MessageContent";
import { useAssistants } from "../../../state/assistants";
import { BiExpand } from "react-icons/bi";
import { BiCollapse } from "react-icons/bi";
import { useChildRuns } from "../../../state/childRuns";
import { useChats } from "../../../state/chats";



interface Position {
  top: number;
  left: number;
  right?: number;
  bottom?: number;
  width?: number;
  height?: number;
}

interface SubRunCardProps {
  streamRunning: boolean;
  subRunId: string;
  expandedVersion?: boolean;
  onExpand?: (expanded: boolean) => void;
  position?: Position;
}

const awaitAnimation = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const SubRunCard: React.FC<SubRunCardProps> = ({ streamRunning, subRunId, expandedVersion, onExpand, position }) => {
  const childRuns = useChildRuns();
  const chatsState = useChats();
  const assistantsState = useAssistants();
  const ref = useRef<HTMLDivElement>(null);
  const [ rect, setRect ] = useState<DOMRect | null>(null);

  const [ renderExpanded, setRenderExpanded ] = useState(false);
  const [ animateExpanded, setAnimateExpanded ] = useState(false);


  const contentRef = useRef<HTMLDivElement>(null);
  
  const [isUserScrolled, setIsUserScrolled] = useState(false);

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = contentRef.current;
      if (scrollHeight - (scrollTop + clientHeight) < 20) {
        setIsUserScrolled(false);
      } else {
        setIsUserScrolled(true);
      }
    }
  }

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: contentRef.current.scrollHeight, behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const expand = () => {
    setRenderExpanded(true);
    measureSizeAndPosition();
  }

  const collapse = async () => {
    setAnimateExpanded(false);
    await awaitAnimation(300);
    setRenderExpanded(false);
    setRect(null);
  }


  const message = childRuns.messages[ subRunId ];
  const run = chatsState.runs[ subRunId ];
  const assistantId = run?.assistantId;
  const assistant = assistantsState.assistants[ assistantId ];



  useEffect(() => {
    childRuns.startStreamingChildRun(subRunId);
    chatsState.loadRunById(subRunId);
    //eslint-disable-next-line
  }, [ subRunId ]);

  const measureSizeAndPosition = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setRect(rect);
    }
  }

  useEffect(() => {
    if (assistantId) {
      assistantsState.loadById({ assistantId, fallbackToDefault: true });
    }

    //eslint-disable-next-line
  }, [ assistantId ]);

  useEffect(() => {
    if (renderExpanded && rect) {
      requestAnimationFrame(() => {
        setAnimateExpanded(true);
      });
    }
  }, [ renderExpanded, rect ])

  useEffect(() => {
    if (!isUserScrolled) {
      scrollToBottom();
    }

    //eslint-disable-next-line
  }, [ message ]);

  useEffect(() => {
    if (!streamRunning) {
      // scrollToBottom();
      scrollToTop();
    }

    //eslint-disable-next-line
  }, [ streamRunning ]);


  const width = streamRunning ? 500 : 300;
  const height = streamRunning ? 400 : 250;

  const expandedPaddingW = window.innerWidth * 0.1;
  const expandedPaddingH = window.innerHeight * 0.1;
  return (
    <>
      <VStack
        ref={ref}
        sx={{
          transition: 'all .3s ease-in-out',
          width,
          height,
          borderColor: useColorModeValue('gray.200', 'gray.700'),
          borderWidth: 1,
          borderRadius: '8px',
          flexShrink: 0,
          overflow: 'hidden',
          backgroundColor: useColorModeValue('white', 'gray.800'),
          ...(expandedVersion && {
            position: 'absolute',
            zIndex: 1000,
            top: `${position?.top}px`,
            left: `${position?.left}px`,
            right: `${position?.right}px`,
            bottom: `${position?.bottom}px`,
            width: position?.width ? `${position.width}px` : 'auto',
            height: position?.height ? `${position.height}px` : 'auto',
          }),
        }}
      >
        <HStack sx={{ width: '100%', px: 2, py: 1, bgColor: useColorModeValue('gray.100', 'gray.700') }}>
          {
            assistant ? (
              <Text
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  flexGrow: 1,
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
              >
                {assistant?.displayName}
              </Text>
            ) : (
              <Skeleton width="100%" height="20px" />
            )
          }
          <IconButton
            aria-label="Expand"
            icon={
              expandedVersion ? <BiCollapse /> : <BiExpand />
            }
            variant="ghost"
            onClick={() => {
              if (onExpand) {
                onExpand(!renderExpanded);
              }
              else {
                expand();
              }
            }}
          />
        </HStack>
        <Box
          ref={contentRef}
          sx={{ 
            overflow: 'scroll', 
            width: '100%', 
            height: '100%', 
            pl: 2, 
            pr: 2, 
            pb: 2, 
            pt: 0, 
            position: 'relative',
          }}
          onScroll={onScroll}
        >
          {message && <MessageContent message={message} />}
        </Box>
      </VStack>
      {
        renderExpanded && rect && (
          <Portal>
            <>
              <Box
                onClick={collapse}
                sx={{
                  transition: 'all .3s ease-in-out',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  opacity: animateExpanded ? 0.5 : 0,
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 1000,
                }}
              />
              <SubRunCard
                streamRunning={streamRunning}
                subRunId={subRunId}
                expandedVersion={true}
                position={!animateExpanded ? {
                  top: rect.y,
                  left: rect.left,

                  width: rect.width,
                  height: rect.height,
                } : {
                  top: expandedPaddingH,
                  left: expandedPaddingW,
                  right: expandedPaddingW,
                  bottom: expandedPaddingH,
                  width: window.innerWidth - expandedPaddingW * 2,
                  height: window.innerHeight - expandedPaddingH * 2,
                }}
                onExpand={() => {
                  collapse();
                }}
              />
            </>
          </Portal>
        )
      }
    </>
  );
}

export default SubRunCard;