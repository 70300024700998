import {
  Text,
  VStack,
  Box,
  HStack,
  Button,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdLanguage } from "react-icons/md";
import { CustomRendererProps } from "./customRenderers";
import MessageText from "../MessageText";
import * as animationData from './loading-animation.json'
import Lottie from "react-lottie";

import SubRuns from "../SubRuns";
import { useChats } from "../../../../state/chats";
import FeedbackActions from "./FeedbackActions";



const AskScendRenderer: React.FC<CustomRendererProps> = ({ message, onFeedback, onShowSources, streamRunning }) => {
  const chatsState = useChats();

  const run = chatsState.runs[ message.runId ];


  useEffect(() => {
    if (!streamRunning) {
      return;
    }

    const timer = setInterval(() => {
      console.log('reload run', message.runId);
      chatsState.reloadRun(message.runId);
    }, 1000);

    return () => clearInterval(timer);
    //eslint-disable-next-line
  }, [ streamRunning ]);

  let json = '';
  for (const content of message.content) {
    if (content.content.case === 'text') {
      json = content.content.value;
      break;
    }
  }

  let data: any;


  json = json.replace(/^json\s*/, '').trim();

  let isLoading = true;
  try {
    data = JSON.parse(json);
    isLoading = false;
  }
  catch (e) {
    console.log('error', e);
  }

  const detialsBorderColor = useColorModeValue('gray.200', 'gray.700');
  const iconColor = useColorModeValue('#222', '#eee');


  // const maxWidth = 1200;

  // console.log('ask ascend', run?.childRunIds);

  return (
    <Box sx={{ width: '100%' }} id="ask-vstack-box">
      <VStack align="start" width="100%" id="ask-vstack" >
        {
          run?.childRunIds.length > 0 && (
            <>
              <SubRuns childRunIds={run?.childRunIds || []} streamRunning={streamRunning} />
            </>
          )
        }
        {
          isLoading ? (
            <Box>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                height={40}
                width={45} />
            </Box>
          ) : (
            <>
              <HStack>
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_766_2180)">
                    <path d="M6.5 16.5L4.86312 12.1369L0.5 10.5L4.86312 8.86312L6.5 4.5L8.13688 8.86312L12.5 10.5L8.13688 12.1369L6.5 16.5Z" fill={iconColor} />
                    <path d="M2.75 6L2.01344 3.98656L0 3.25L2.01344 2.51344L2.75 0.5L3.48656 2.51344L5.5 3.25L3.48656 3.98656L2.75 6Z" fill={iconColor} />
                    <path d="M12.5 8.5L11.5278 5.97219L9 5L11.5278 4.02781L12.5 1.5L13.4722 4.02781L16 5L13.4722 5.97219L12.5 8.5Z" fill={iconColor} />
                  </g>
                  <defs>
                    <clipPath id="clip0_766_2180">
                      <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                <Text sx={{ fontSize: 18, fontWeight: '600' }}>Summary</Text>
              </HStack>
              <Box
                sx={{
                  p: 4,
                  // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
                  border: `1px solid`,
                  borderColor: detialsBorderColor,
                  borderRadius: '8px',
                  height: '100%',
                  display: 'flex',
                }}
              >
                <HStack sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      width: '5px',
                      height: '100%',
                      bgColor: 'primary.500',
                      borderRadius: '10px',
                      flexShrink: 0,
                    }}
                  />
                  <VStack align="start" sx={{ my: 1, flexGrow: 1 }}>
                    <MessageText text={data.answer_summary} />
                      
                    {/* <Button
                    size="xs"
                    variant="ghost"
                    leftIcon={
                      <Icon as={MdLanguage} fontSize="13px" />
                    }
                    onClick={() => {
                      onShowSources(
                        message.runId,
                        data.answer_details.client_facing_info.relevant_sources,
                      );
                    }}
                  >
                    Sources
                  </Button> */}
                  </VStack>
                </HStack>
              </Box>
              <HStack>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 3.5C1.72386 3.5 1.5 3.72386 1.5 4V5C1.5 5.27614 1.72386 5.5 2 5.5H3C3.27614 5.5 3.5 5.27614 3.5 5V4C3.5 3.72386 3.27614 3.5 3 3.5H2Z" fill={iconColor} />
                  <path d="M4.5 5.25H14.5V3.75H4.5V5.25Z" fill={iconColor} />
                  <path d="M4.5 8.75H14.5V7.25H4.5V8.75Z" fill={iconColor} />
                  <path d="M14.5 12.25H4.5V10.75H14.5V12.25Z" fill={iconColor} />
                  <path d="M1.5 7.5C1.5 7.22386 1.72386 7 2 7H3C3.27614 7 3.5 7.22386 3.5 7.5V8.5C3.5 8.77614 3.27614 9 3 9H2C1.72386 9 1.5 8.77614 1.5 8.5V7.5Z" fill={iconColor} />
                  <path d="M2 10.5C1.72386 10.5 1.5 10.7239 1.5 11V12C1.5 12.2761 1.72386 12.5 2 12.5H3C3.27614 12.5 3.5 12.2761 3.5 12V11C3.5 10.7239 3.27614 10.5 3 10.5H2Z" fill={iconColor} />
                </svg>
                <Text sx={{ fontSize: 16, fontWeight: '600' }}>Details</Text>
              </HStack>

              <Box sx={{
                // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
                borderRadius: '8px',
                height: '100%',
                display: 'flex',
              }}>


                <HStack sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      width: '5px',
                      height: '100%',
                      bgColor: detialsBorderColor,
                      borderRadius: '10px',
                      flexShrink: 0,
                    }}
                  />
                  <VStack align="start" sx={{ flexGrow: 1 }}>
                    <MessageText text={data.answer_details.client_facing_info.answer as string} />
                    <Button
                      size="xs"
                      variant="ghost"
                      leftIcon={
                        <Icon as={MdLanguage} fontSize="13px" />
                      }
                      onClick={() => {
                        onShowSources(
                          message.runId,
                          data.answer_details.client_facing_info.relevant_sources,
                        );
                      }}
                    >
                      Sources
                    </Button>
                  </VStack>
                </HStack>
              </Box>
              <FeedbackActions runId={message.runId} onFeedback={onFeedback} />
            </>
          )
        }
      </VStack>
    </Box>
  );
};

export default AskScendRenderer;