import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Switch,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAssistantsDialog } from "../../AssistantsDialog";
import { MdAddCircle, MdDelete } from "react-icons/md";
import { useAssistants } from "../../../state/assistants";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Assistant } from "../../../gen-ts/ai/assistants/v0/assistant_pb";
import FileManagerProvider from "../FileManager/FileManagerProvider";
import FileManager from "../FileManager/FileManager";
import SubAssistantsList from "./SubAssistantsList";



interface Props {
  assistant: Assistant;
  onUpdate: (assistant: Assistant) => void;
  disabled: boolean;
}

const TabAssistantAdvanced: React.FC<Props> = ({ assistant, onUpdate, disabled }) => {
  const [ data, setData ] = useState<Assistant>(assistant);

  const [ selectionPath, setSelectionPath ] = useState<string[]>([]);

  useEffect(() => {
    if (assistant) {
      setData(assistant.clone());
    }
    //eslint-disable-next-line
  }, [ assistant ]);

  const assistantsDialog = useAssistantsDialog();
  const assistants = useAssistants();

  const onOpen = () => {
    assistantsDialog.open({
      title: 'Select Sub Assistant',
      unselectableIds: data.subAssistants,
      onSelect: (assistantId) => {
        data.subAssistants.push(assistantId);
        setData(data.clone());
        onUpdate(data.clone());
        setSelectionPath([ assistantId ]);
      }
    });
  }

  const deleteSubAssistant = (id: string) => {
    data.subAssistants = data.subAssistants.filter((assistantId) => assistantId !== id);
    setData(data.clone());
    onUpdate(data.clone());
  }

  const subAssistants = data.subAssistants ?? [];
  const selectedSubAssistant = selectionPath.length > 0 ? selectionPath[ selectionPath.length - 1 ] : null;

  return (
    <VStack bgColor={useColorModeValue('gray.100', 'gray.700')} p={4} borderRadius={10} align="start">
      <HStack width="100%" mb={3}>
        <Text flexGrow={1} fontWeight="600">Sub Assistants</Text>
        <Text>Enable as tools</Text>
        <Switch
          isDisabled={disabled}
          size='lg'
          colorScheme="primary"
          mr={3}
          isChecked={data.subAssistantsAsTools}
          onChange={(e) => {
            data.subAssistantsAsTools = e.target.checked;
            setData(data.clone());
            onUpdate(data.clone());
          }}
        />
      </HStack>

      <HStack sx={{
        width: '100%',
        borderRadius: 10,
        bgColor: useColorModeValue('white', 'gray.800'),
        p: 4,
        alignItems: 'stretch',
        minHeight: 600,
      }}>

        <VStack sx={{
          pr: 4,
          borderRight: '1px solid',
          borderRightColor: useColorModeValue('gray.200', 'gray.600'),
          flexGrow: 1,
          minWidth: 200,
          maxWidth: 400,
          alignItems: 'start',
          overflowX: 'auto',
        }}>

          <SubAssistantsList
            assistant={data}
            nestedLevel={0}
            onSelect={setSelectionPath}
            selectionPath={selectionPath}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Button sx={{ width: '100%' }} leftIcon={<MdAddCircle />} variant="outline" onClick={onOpen}>
            Add Sub Assistant
          </Button>
        </VStack>

        {
          subAssistants.length === 0 && (
            <VStack align="center" justify="center" sx={{ width: '100%', height: '100%' }}>
              <Text>No sub assistants available</Text>
            </VStack>
          )
        }

        {
          selectedSubAssistant ? (
            <VStack sx={{ pl: 2, width: '100%', alignItems: 'start' }}>
              <HStack sx={{ width: '100%' }}>
                <Text fontWeight="600" fontSize={18} sx={{ flexGrow: 1 }}>
                  {assistants.assistants[ selectedSubAssistant! ]?.displayName}
                </Text>
                <HStack>
                  <Button
                    size="sm"
                    rightIcon={<ExternalLinkIcon />}
                    as={'a'}
                    href={`/assistants/${selectedSubAssistant}`}
                    target="_blank"
                    variant="outline">
                    Edit
                  </Button>
                  <IconButton
                    disabled={disabled}
                    aria-label="delete sub assistant"
                    icon={<MdDelete />}
                    onClick={() => {
                      deleteSubAssistant(selectedSubAssistant);
                      setSelectionPath([]);                      
                    }}
                    isDisabled={selectionPath.length > 1}
                    variant="outline"
                    size="sm"
                  />
                </HStack>
              </HStack>
              <HStack>
                <Badge sx={{ px: 2, py: 1, borderRadius: 10 }}>SUB ASSISTANT</Badge>
                <Text fontSize={14}>{assistants.assistants[ selectedSubAssistant! ]?.description}</Text>
              </HStack>
              <Divider />

              {
                assistants.assistants[ selectedSubAssistant! ] && (
                  <FileManagerProvider
                    key={selectedSubAssistant}
                    assistant={assistants.assistants[ selectedSubAssistant! ]}
                    subAssistantView={true}
                  >
                    <FileManager
                      assistantId={selectedSubAssistant}
                    />
                  </FileManagerProvider>
                )
              }

            </VStack>
          ) : (
            <VStack sx={{ pl: 2, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Text fontWeight={600}>Select a sub assistant to view details</Text>
            </VStack>
          )
        }



      </HStack>


    </VStack>
  );
};

export default TabAssistantAdvanced;
