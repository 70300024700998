import { Box, VStack } from "@chakra-ui/react";
import { CustomRendererProps } from "./customRenderers";
import MessageContent from "../MessageContent";
import FeedbackActions from "./FeedbackActions";
import { useChats } from "../../../../state/chats";
import SubRuns from "../SubRuns";
import Lottie from "react-lottie";
import * as animationData from './loading-animation.json'
import { useEffect } from "react";

const AskScendStreamingRenderer: React.FC<CustomRendererProps> = ({ message, onFeedback, onShowSources, streamRunning }) => {
  const chatsState = useChats();

  useEffect(() => {
    if (!streamRunning) {
      return;
    }

    const timer = setInterval(() => {
      console.log('reload run', message.runId);
      chatsState.reloadRun(message.runId);
    }, 1000);

    return () => clearInterval(timer);
    //eslint-disable-next-line
  }, [ streamRunning ]);

  const run = chatsState.runs[ message.runId ];

  return (
    <VStack align="start" width="100%" id="ask-vstack" >
      {
        run?.childRunIds.length > 0 && (
          <SubRuns childRunIds={run?.childRunIds || []} streamRunning={streamRunning} />
        )
      }
      <MessageContent message={message} />
      {
        streamRunning && (
          <Box>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={40}
              width={45} />
          </Box>
        )
      }
      {
        !streamRunning && (
          <FeedbackActions runId={message.runId} onFeedback={onFeedback} />
        )
      }
    </VStack>
  )
}


export default AskScendStreamingRenderer;