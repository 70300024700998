import React, { DragEvent, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Assistant } from "../../../gen-ts/ai/assistants/v0/assistant_pb";
import { AssistantFile, AssistantFileStatus } from "../../../types";

import { getRootFolder, ROOT_PATH, traverseFileTree, useAssistantFiles } from "../../../state/assistantFiles";
import { useAssistants } from "../../../state/assistants";
import { useUserState } from "../../../state/user";



interface DropZoneProps {
  onDrop?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragStart?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragEnd?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragLeave?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragOver?: (ev: DragEvent<HTMLDivElement>) => void,
}

interface DragItemProps {
  onDragStart?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragEnd?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragLeave?: (ev: DragEvent<HTMLDivElement>) => void,
  draggable?: boolean;
}


interface IFileManagerContext {
  assistant: Assistant;
  currentPath: string;
  currentFolder: AssistantFile | null;
  setCurrentPath: (path: string) => void,
  activeDropZone: string | null;
  activeDragItem: string | null;
  getDropZoneProps: () => DropZoneProps;
  getDragItemProps: () => DragItemProps;
  openFilePicker: () => void;
  hasFilePermission: boolean;
  subAssistantView: boolean;
  managedBy?: string;

}

const FileManagerContext = React.createContext<IFileManagerContext>({
  assistant: new Assistant(),
  currentPath: ROOT_PATH,
  currentFolder: null,
  setCurrentPath: (path: string) => { },
  activeDropZone: null,
  activeDragItem: null,
  getDropZoneProps: () => ({}),
  getDragItemProps: () => ({}),
  openFilePicker: () => { },
  hasFilePermission: false,
  subAssistantView: false,

});

interface Props {
  assistant: Assistant;
  subAssistantView?: boolean;
}
const FileManagerProvider: React.FC<PropsWithChildren<Props>> = ({ children, assistant, subAssistantView }) => {
  const assistantsFiles = useAssistantFiles();
  const assistantsStates = useAssistants();
  const user = useUserState();
  const [ currentPath, setCurrentPath ] = useState(getRootFolder().path);

  const statusUpdateInterval = useRef<NodeJS.Timeout | null>(null);


  const [ activeDragItem, setActiveDragItem ] = useState<string | null>(null);
  const [ activeDropZone, setActiveDropZone ] = useState<string | null>(null);

  const dropZoneEnterTime = useRef<number | null>(null);

  const managedBy = assistantsFiles.stores[ assistant.storeId ]?.managedBy ? assistantsFiles.stores[ assistant.storeId ]?.managedBy : undefined;

  // const [ rootFolder, setRootFolder ] = useState<AssistantFile>(getRootFolder());

  const currentFolder = useMemo(() => {
    return assistantsFiles.getFolderByPath(assistant.id, currentPath);

    //eslint-disable-next-line
  }, [ currentPath, assistantsFiles.files, assistant.id ]);


  const isInUploaderGroup = () => {
    const domain = (user.user?.email.split('@')[ 1 ])?.toLowerCase();
    return (
      assistant.uploaders.includes(`domain:${domain}`) || assistant.owners.includes(`domain:${domain}`) ||
      assistant.uploaders.includes(`user:${user.user?.email}`) || assistant.owners.includes(`user:${user.user?.email}`)
    );
  }

  const hasFilePermission = !subAssistantView && (Boolean(assistant.storeId) ?
    (assistantsStates.permissions[ assistant.id ]?.canUpload || false) :
    isInUploaderGroup());


  useEffect(() => {
    fetchFiles();

    return () => {
      resetFileWatcher();
    }

    //eslint-disable-next-line
  }, [ currentPath, assistant.id ]);


  useEffect(() => {
    if (assistant.storeId) {
      assistantsFiles.loadStore(assistant.storeId);
    }

    //eslint-disable-next-line
  }, [ assistant.storeId ])

  const fetchFiles = useCallback(async () => {
    const hasPending = await assistantsFiles.fetchFiles(assistant.id, currentPath);
    if (hasPending) {
      watchForChanges();
    }

    //eslint-disable-next-line
  }, [ currentPath, assistant.id ])


  const resetFileWatcher = () => {
    clearInterval(statusUpdateInterval.current!);
    statusUpdateInterval.current = null;
  }


  const watchForChanges = () => {
    assistantsFiles.filesStatusUpdate(assistant.id, currentPath);
    resetFileWatcher();
    statusUpdateInterval.current = setInterval(async () => {
      const stillPending = await assistantsFiles.filesStatusUpdate(assistant.id, currentPath);
      if (!stillPending && statusUpdateInterval.current) {
        clearInterval(statusUpdateInterval.current);
      }
    }, 3000);

    //eslint-disable-next-line
  };

  const ref = useRef<HTMLInputElement>(null);

  const onDrop = async (ev: DragEvent<HTMLDivElement>) => {
    if (!hasFilePermission || managedBy) {
      return;
    }

    console.log("dropped on", (ev.target as HTMLDivElement).id);
    ev.preventDefault();

    setActiveDropZone(null);
    setActiveDragItem(null);

    if (!currentFolder) {
      console.error('Current folder not found');
      return;
    }

    if (ev.dataTransfer.files.length > 0) {
      const files: AssistantFile[] = [];
      const itemList = [ ...ev.dataTransfer?.items || [] ];
      const entryList: FileSystemEntry[] = [];

      // Convert DataTransferItem to FileSystemEntry synchronously
      for (let i = 0; i < itemList.length; i++) {
        const item = itemList[ i ];
        const entry = item.webkitGetAsEntry();
        if (entry) {
          entryList.push(entry);
        }
      }


      const targetPath = activeDropZone || currentFolder?.path || ROOT_PATH;
      for (let i = 0; i < entryList.length; i++) {
        const entry = entryList[ i ];
        if (entry) {
          const item = await traverseFileTree(entry, targetPath, targetPath);
          files.push(item);
        }
      }

      assistantsFiles.uploadFiles({
        assistantId: assistant.id,
        files,
        path: targetPath,
      });
      watchForChanges();
    }
    else {
      const file = ev.dataTransfer.getData("assistant-file");
      console.log('file', file);
    }
  }

  const onDragEnter = (ev: DragEvent<HTMLDivElement>) => {
    if (!hasFilePermission || managedBy) {
      return;
    }

    const dropZonePath = (ev.target as HTMLDivElement).getAttribute('data-path');
    const dropZoneIsFile = (ev.target as HTMLDivElement).getAttribute('data-file') === 'true';

    if (!dropZonePath) {
      console.warn('no drop zone path found', dropZonePath);
      return;
    }

    if (dropZoneIsFile) {
      console.log('drop zone is file', dropZonePath);
      dropZoneEnterTime.current = null;
      return;
    }

    dropZoneEnterTime.current = Date.now();

    setActiveDropZone(dropZonePath);

    ev.preventDefault();
  }

  const onDragEndContainer = (ev: DragEvent<HTMLDivElement>) => {
    setActiveDropZone(null);
    // ev.preventDefault();
  }

  const onItemDragStart = (ev: DragEvent<HTMLDivElement>) => {
    const itemPath = (ev.target as HTMLDivElement).getAttribute('data-path');
    const isFile = (ev.target as HTMLDivElement).getAttribute('data-file') === 'true';
    const isDir = (ev.target as HTMLDivElement).getAttribute('data-dir') === 'true';
    console.log('drag start', itemPath, ev.target);

    if (!itemPath) {
      return;
    }

    ev.dataTransfer.setData("dragging-item-path", itemPath);
    ev.dataTransfer.setData("is-dir", isDir ? 'true' : 'false');
    ev.dataTransfer.setData("is-file", isFile ? 'true' : 'false');
    setActiveDragItem(itemPath);
    console.log('set active drag item', itemPath);
  }

  const onItemDragEnd = (ev: DragEvent<HTMLDivElement>) => {
    dropZoneEnterTime.current = null;
    setActiveDragItem(null);
  }


  const openFilePicker = () => {
    if (!hasFilePermission) {
      return;
    }
    if (ref.current) {
      ref.current.click();
    }
  }

  const onFileChoose = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const list: AssistantFile[] = [];
    for (const file of ev.target.files || []) {
      const assistantFile: AssistantFile = {
        type: 'file',
        name: file.name,
        file: file,
        path: ((currentFolder?.path || ROOT_PATH) + '/' + file.name).replace(/\/+/g, '/'),
        progress: 0,
        status: AssistantFileStatus.uploading,
        children: [],
      }

      list.push(assistantFile);
    }

    if (list.length > 0) {
      assistantsFiles.uploadFiles({
        assistantId: assistant.id,
        files: list,
        path: currentFolder?.path || ROOT_PATH,
      });
      watchForChanges();
    }
  }

  const onDragOver = (ev: DragEvent<HTMLDivElement>) => {
    if (
      dropZoneEnterTime.current &&
      Date.now() - dropZoneEnterTime.current! > 500 &&
      activeDropZone &&
      currentFolder?.path !== activeDropZone
    ) {
      setCurrentPath(activeDropZone);
      dropZoneEnterTime.current = null;
    }
    ev.preventDefault();
  }

  



  const context: IFileManagerContext = {
    assistant,
    currentFolder: currentFolder,
    currentPath: currentPath || '',
    setCurrentPath,
    activeDragItem,
    activeDropZone,
    openFilePicker,
    hasFilePermission: hasFilePermission,
    subAssistantView: subAssistantView || false,
    managedBy: managedBy,
    getDropZoneProps: () => {
      if (!hasFilePermission) {
        return {};
      }

      return {
        onDrop,
        onDragEnter,
        onDragEnd: onDragEndContainer,
        onDragLeave: onDragEndContainer,
        onDragOver,
      };
    },
    getDragItemProps: () => {
      if (!hasFilePermission) {
        return {};
      }

      return {
        onDragStart: onItemDragStart,
        onDragEnd: onItemDragEnd,
        // onDragLeave: onItemDragEnd,
        draggable: true,
      };
    },
  };



  return (
    <FileManagerContext.Provider value={context}>
      {children}
      <input type="file" ref={ref} hidden onChange={onFileChoose} multiple />
    </FileManagerContext.Provider>
  );
};

export default FileManagerProvider;


export const useFileManager = () => {
  return useContext(FileManagerContext);
}