import { Message, MessageContent, MessageContent_Citation } from "../gen-ts/ai/assistants/v0/assistant_pb";

export const replaceMessageCitations = (originalMessage: Message) => {
  const message = originalMessage.clone();
  const content = message.content;

  for (const contentItem of content) {
    const citations = getCitationsList(contentItem);
    let count = 1;
    if (contentItem.content.case === 'text') {
      for (let i=0; i<citations.length; i++) {
        const citation = citations[ i ];
        const tag = citation.tag.trim();
        if (citation.metadata[ 'slack_message_timestamp' ]) {
          contentItem.content.value = contentItem.content.value.replace(tag, '');
        }
        else {
          contentItem.content.value = contentItem.content.value.replace(tag, `[[${count}]](${citation.url})`);
          count++;
        }
      }
    }
  }
  return message;
}

export const replaceMessageListCitations = (originalMessages: Message[]) => {
  const messages = originalMessages.map(message => replaceMessageCitations(message));
  return messages;
}



const getCitationsList = (content: MessageContent) => {
  const citations: MessageContent_Citation[] = [];
  if (content.citations) {
    for (const citation of content.citations) {
      citations.push(citation);
    }
  }
  return citations;
}