import { Box, Link, useColorModeValue } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

const MessageText: React.FC<{ text: string }> = ({ text }) => {
  const boxBgColor = useColorModeValue('gray.100', 'gray.700');
  return (
    <Box
      overflowY="hidden"
      overflowX="auto"
      // maxWidth={900}
      width="100%"
    >
      <ReactMarkdown
        rehypePlugins={[ rehypeRaw, rehypeSanitize ]}
        skipHtml={false}
        components={{
          code({ node, className, children, ...props }) {
            const isBlockCode = className !== undefined;
            return isBlockCode ? (
              // Code block
              <Box
                as="pre"
                bg={boxBgColor}
                p={2}
                borderRadius="md"
                overflow="auto"
                // maxWidth={900}
                width="100%"
              >
                <code className={className} {...props}>
                  {children}
                </code>
              </Box>
            ) : (
              // Inline code
              <Box
                as="code"
                bg={boxBgColor}
                p="0.2em"
                borderRadius="md"
                // maxWidth={900}
                width="100%"
                {...props}
              >
                {children}
              </Box>
            );
          },
          ul: ({ children }) => <ul style={{ marginLeft: '20px' }}>{children}</ul>,
          ol: ({ children }) => <ol style={{ marginLeft: '20px' }}>{children}</ol>,
          li: ({ children }) => <li style={{ marginLeft: '20px' }}>{children}</li>,
          a: ({ children, href }) => <Link href={href} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'underline', color: 'primary.600' }}>{children}</Link>,
        }}
      >
        {text}
      </ReactMarkdown>
    </Box>
  );
}

export default MessageText;