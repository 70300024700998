import { create } from "zustand";


import { AssistantFile, AssistantFileStatus } from "../types";
import { IndexFile_State, ListIndexFilesRequest } from "../gen-ts/ai/rag/v0/stores_pb";
import { useAssistants } from "./assistants";
import { Assistant } from "../gen-ts/ai/assistants/v0/assistant_pb";
import { CreateStoreRequest, DeleteFileRequest, Store, UpdateStoreRequest, UploadFileRequest } from "../gen-ts/ai/stores_pb";
import { StringList } from "../gen-ts/ai/type/list_pb";
import { getFileListClient, getStoreClient } from "../api";
import axios from "axios";

interface UploadFilesArgs {
  assistantId: string;
  files: AssistantFile[];
  path: string;
}

interface AssistantFiles {
  loading: {
    [ assistantId: string ]: boolean;
  },
  files: {
    [ assistantId: string ]: AssistantFile;
  },
  stores: {
    [ storeId: string ]: Store;
  },
  uploadFiles: (args: UploadFilesArgs) => void;
  fetchFiles: (storeId: string, folder: string) => Promise<boolean>;
  getFolderByPath: (assistantId: string, path: string) => AssistantFile | null;
  createStore: (assistant: Assistant) => Promise<string>;
  updateStore: (assistant: Assistant) => void;
  filesStatusUpdate: (assistantId: string, path: string) => Promise<boolean>;
  deleteFile: (assistantId: string, filePath: string, currentPath: string) => void;
  loadStore: (storeId: string) => void;
}



export const useAssistantFiles = create<AssistantFiles>(
  (set, get) => ({
    loading: {},
    files: {},
    stores: {},
    uploadFiles: async ({ assistantId, files, path }) => {
      const assistant = useAssistants.getState().assistants[ assistantId ];
      let storeId = assistant.storeId;
      if (!assistant) {
        console.error('assistant not found', assistantId);
        return;
      }
      if (!storeId) {
        storeId = await get().createStore(assistant);
        if (!storeId) {
          console.error('error creating created');
          return;
        }

        assistant.storeId = storeId;
        const updated = assistant.clone();
        useAssistants.getState().setAssistants([ updated ]);
        useAssistants.getState().saveAssistant(updated);
      }
      const root = get().files[ assistantId ] || getRootFolder();
      const folder = findFolderByPath(root, path);
      if (!folder) {
        console.error('folder not found', path);
        return;
      }

      let children = folder.children.filter((f) => !files.some((file) => file.name === f.name));

      folder.children = [
        ...files,
        ...children,
      ];

      set({ files: { ...get().files, [ assistantId ]: cloneFile(root) } });

      const { client, headers } = getStoreClient();

      const uploadChildren = async (children: AssistantFile[]) => {
        for (const file of children) {
          if (file.status === AssistantFileStatus.error) {
            continue;
          }
          if (file.type === 'folder') {
            uploadChildren(file.children);
          }
          else {
            const filePath = file.path.substring(1); //remove initial slash
            const request = new UploadFileRequest({
              storeId: assistant.storeId,
              filename: filePath,
            });

            try {
              const allocationResponse = client.uploadFile(request, { headers });
              for await (const response of allocationResponse) {
                if (response.event?.case === "allocated") {
                  uploadFileToGCS({
                    file: file.file!,
                    signedUrl: response.event.value.url,
                    headers: response.event.value.headers,
                    onProgress: (progress) => {
                      file.progress = progress;
                      if (progress === 100) {
                        file.status = AssistantFileStatus.indexing;
                        setAllParentFoldersStatus(root, file, AssistantFileStatus.indexing);
                      }
                      set({ files: { ...get().files, [ assistantId ]: cloneFile(root) } });
                    },
                    onError: (error) => {
                      file.status = AssistantFileStatus.error;
                      file.error = error;
                      set({ files: { ...get().files, [ assistantId ]: cloneFile(root) } });
                    },
                  })

                }
              }
            }
            catch (e) {
              console.error('upload file error', e);
              file.status = AssistantFileStatus.error;
              file.error = (e as Error).message;
              set({ files: { ...get().files, [ assistantId ]: cloneFile(root) } });
            }
          }
        }
      }
      uploadChildren(files);
    },
    loadStore: async (storeId: string) => {
      const { client, headers } = getStoreClient();
      const store = await client.getStore({ id: storeId }, { headers });
      set({ stores: { ...get().stores, [ storeId ]: store } });
    },
    fetchFiles: async (assistantId: string, path: string): Promise<boolean> => {
      const assistant = useAssistants.getState().assistants[ assistantId ];
      const storeId = assistant?.storeId;
      if (!assistant || !storeId) {
        return false;
      }

      const rootFolder = get().files[ assistantId ] || getRootFolder();

      const folder = findFolderByPath(rootFolder, path);

      if (folder && folder.children.length === 0) {

        set({ loading: { ...get().loading, [ assistantId ]: true } });

        try {
          const serverFiles = await listFiles(storeId, path);
          for (const file of serverFiles) {
            folder.children.push(file);
          }

          set({ files: { ...get().files, [ assistantId ]: cloneFile(rootFolder) } });
        }
        catch (e) {
          console.error('fetch files error', e);
        }

        set({ loading: { ...get().loading, [ assistantId ]: false } });
      }


      if (folder) {
        return hasAnyPendingChildren(folder);
      }
      else {
        return false;
      }
    },
    getFolderByPath: (assistantId: string, path: string) => {
      const root = get().files[ assistantId ] || getRootFolder();

      if (path === root.path) {
        return root;
      }

      return findFolderByPath(root, path);
    },
    createStore: async (assistant: Assistant): Promise<string> => {
      try {
        const { client, headers } = getStoreClient();
        const request = new CreateStoreRequest({
          displayName: assistant.displayName,
          owners: [
            ...assistant.owners,
          ],
          uploaders: [
            ...assistant.uploaders,
          ],
        });
        const resp = await client.createStore(request, { headers });
        return resp.id;
      }
      catch (e) {
        console.error('create file store error', e);
      }
      return '';
    },
    updateStore: async (assistant: Assistant) => {
      if (!assistant.storeId) {
        return;
      }

      try {
        const { client, headers } = getStoreClient();
        const request = new UpdateStoreRequest({
          id: assistant.storeId,
          displayName: assistant.displayName,
          owners: new StringList({
            values: [
              ...assistant.owners,
            ],
          }),
          uploaders: new StringList({
            values: [
              ...assistant.uploaders,
            ],
          }),
        });
        const resp = await client.updateStore(request, { headers });
        return resp.id;
      }
      catch (e) {
        console.error('error updating file store', e);
      }
    },
    filesStatusUpdate: async (assistantId: string, path: string) => {

      const assistant = useAssistants.getState().assistants[ assistantId ];
      const storeId = assistant.storeId;
      if (!assistant || !storeId) {
        return false;
      }

      const root = get().files[ assistantId ] || getRootFolder();
      const targetFolder = findFolderByPath(root, path);
      if (!targetFolder) {
        return false;
      }

      let stillPending = false;

      try {
        const serverFiles = await listFiles(storeId, path);
        for (const file of serverFiles) {
          const index = targetFolder.children.findIndex((f) => f.path === file.path);
          if (index !== -1) {
            targetFolder.children[ index ].status = file.status;
            targetFolder.children[ index ].error = file.error;
            if (file.status === AssistantFileStatus.indexing) {
              stillPending = true;
            }
          }
        }

        for (const dir of targetFolder.children) {
          if (dir.type !== 'folder' || dir.status !== AssistantFileStatus.uploading) {
            continue;
          }
          dir.status = getDirStatus(dir);
        }
      }
      catch (e) {
        console.error('filesStatusUpdate error', e);
        return false;
      }

      //check for local uploads that are still pending
      if (!stillPending) {
        for (const file of targetFolder.children) {
          if (file.status === AssistantFileStatus.uploading || file.status === AssistantFileStatus.indexing) {
            stillPending = true;
            break;
          }
        }
      }

      set({ files: { ...get().files, [ assistantId ]: cloneFile(root) } });


      return stillPending;
    },
    deleteFile: async (assistantId: string, filePath: string, currentPath: string) => {
      const assistant = useAssistants.getState().assistants[ assistantId ];
      const storeId = assistant.storeId;
      if (!assistant || !storeId) {
        return;
      }

      const root = get().files[ assistantId ] || getRootFolder();
      const targetFolder = findFolderByPath(root, currentPath);
      if (!targetFolder) {
        return;
      }

      const { client, headers } = getStoreClient();
      const request = new DeleteFileRequest({
        storeId: storeId,
        filename: filePath.substring(1), //remove initial slash
      });

      try {
        client.deleteFile(request, { headers });
        const index = targetFolder.children.findIndex((f) => f.path === filePath);
        if (index !== -1) {
          targetFolder.children.splice(index, 1);
          set({ files: { ...get().files, [ assistantId ]: cloneFile(root) } });
        }
      }
      catch (e) {
        console.error('delete file error', e);
      }
    }
  })
);

const listFiles = async (storeId: string, folder: string) => {
  var argFolder = folder;
  if (argFolder.startsWith('/')) {
    argFolder = argFolder.substring(1);
  }
  if (!argFolder.endsWith('/')) {
    argFolder += '/';
  }
  if (argFolder === '/') {
    argFolder = '';
  }

  const result: AssistantFile[] = [];
  try {
    const { client, headers } = getFileListClient();

    if (folder.startsWith('/')) {
      folder = folder.substring(1);
    }

    const request = new ListIndexFilesRequest({
      storeId: storeId,
      directory: argFolder,
      pageSize: 1000,
    });

    const resp = await client.listIndexFiles(request, { headers });

    for (const item of resp.files) {
      const isDir = item.info.case === 'directoryInfo';
      let name = '', path = '';
      if (isDir) {
        const nameArr = item.name.split('/').filter(Boolean);
        name = nameArr[ nameArr.length - 1 ];
        path = ('/' + item.name.slice(0, -1)).replace(/\/+/g, '/');
      }
      else {
        let nameArr = item.name.split('/');
        name = nameArr[ nameArr.length - 1 ];
        path = `/${item.name}`.replace(/\/+/g, '/');
      }

      const file: AssistantFile = {
        path: path,
        name: name,
        type: item.info.case === 'directoryInfo' ? 'folder' : 'file',
        progress: 0,
        status: item.info.case === 'directoryInfo' ? serverDirStatusToClient(item.info.value.states) : serverFileStatusToCLient(item.info.value!.state),
        children: [],
        error: item.info.case === 'directoryInfo' ? undefined : item.info.value?.stateReason,
      };
      result.push(file);
    }
  }
  catch (e) {
    console.error('list files error', e);
  }

  return result;
}

const serverFileStatusToCLient = (state: IndexFile_State): AssistantFileStatus => {
  if (state === IndexFile_State.INDEXING) {
    return AssistantFileStatus.indexing;
  }
  else if (state === IndexFile_State.FAILED) {
    return AssistantFileStatus.error;
  }

  return AssistantFileStatus.done;
}

const serverDirStatusToClient = (states: { [ state: string ]: IndexFile_State }): AssistantFileStatus => {
  if (states[ 'INDEXING' ]) {
    return AssistantFileStatus.indexing;
  }
  if (states[ 'FAILED' ]) {
    return AssistantFileStatus.error;
  }

  return AssistantFileStatus.done;
}

const unsupportedExtensions = [
  '.exe',
  '.exec',
  '.dll',
  '.reg',
  '.vbs',
  '.msi',
  '.msg',
  '.zip',
  '.rar',
  '.tar',
  '.tgz',
  '.tar.gz',
  '.vsd',
];

const unsupportedFolders = [ '.git', '.vsd', 'node_modules' ];
const unsupportedFilenames = [ '.DS_Store' ];

const isSupported = (file: AssistantFile): boolean => {

  if (file.type === 'folder') {
    return !unsupportedFolders.includes(file.name);
  }
  else {
    for (const ext of unsupportedExtensions) {
      if (file.name.endsWith(ext)) {
        return false;
      }
    }

    for (const filename of unsupportedFilenames) {
      if (file.name === filename) {
        return false;
      }
    }

    for (const dir of unsupportedFolders) {
      if (file.path.includes(dir)) {
        return false;
      }
    }
  }

  return true;
}

const getFileInstance = async (entry: FileSystemEntry): Promise<File> => {
  return new Promise((resolve, reject) => {
    const fileEntry = entry as FileSystemFileEntry;

    fileEntry.file((file) => {
      resolve(file);
    });
  });
}

const readDirEntries = async (dirEntry: FileSystemDirectoryEntry): Promise<FileSystemEntry[]> => {
  const reader = dirEntry.createReader();

  const children: FileSystemEntry[] = [];

  return new Promise((resolve) => {
    const readEntries = () => {
      reader.readEntries(async (entries) => {
        if (entries.length === 0) {
          resolve(children);
        } else {
          for (const ent of entries) {
            children.push(ent);
          }
          readEntries(); // Continue reading entries if there are more.
        }
      });
    };
    readEntries();
  });
}

export const traverseFileTree = async (entry: FileSystemEntry, currentPath: string, startingPath: string): Promise<AssistantFile> => {
  let path = currentPath;
  if (!path.endsWith('/')) {
    path += '/';
  }
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  let file: AssistantFile | null = null;

  if (entry.isFile) {
    const fileEntry = entry as FileSystemFileEntry;
    const fileInstance = await getFileInstance(fileEntry);
    file = {
      type: 'file',
      path: (path + fileInstance.name).replace(/\/+/g, '/'),
      name: fileInstance.name,
      file: fileInstance,
      progress: 0,
      status: AssistantFileStatus.uploading,
      children: [],
    };
    if (!isSupported(file)) {
      file.status = AssistantFileStatus.error;
      file.error = 'Unsupported file type';
    }
  } else if (entry.isDirectory) {
    const dirEntry = entry as FileSystemDirectoryEntry;
    const dirPath = (startingPath + dirEntry.fullPath).replace(/\/+/g, '/');
    file = {
      type: 'folder',
      path: dirPath,
      name: dirEntry.name,
      progress: 0,
      status: AssistantFileStatus.uploading,
      children: [],
    }
    if (!isSupported(file)) {
      file.error = 'Unsupported folder';
      file.status = AssistantFileStatus.error;
    }
    else {
      const entries = await readDirEntries(dirEntry);
      for (const ent of entries) {
        const item = await traverseFileTree(ent, dirPath, startingPath);
        file!.children.push(item);
      }
    }
  }

  return file!;
}

const findFolderByPath = (root: AssistantFile, targetPath: string): AssistantFile | null => {
  // Split the target path into parts
  const pathParts = targetPath.split('/').filter(Boolean); // remove empty strings from the split
  let currentFolder: AssistantFile | null = root;

  // Traverse through the folder structure
  for (const part of pathParts) {
    // If currentFolder has children, we search for the next folder part
    if (currentFolder && currentFolder.type === 'folder') {
      const nextFolder = currentFolder.children.find(
        (child) => child.type === 'folder' && child.name === part
      ) as AssistantFile | undefined;

      // If folder not found, return null
      if (!nextFolder) {
        return null;
      }
      currentFolder = nextFolder;
    } else {
      return null; // If it's not a folder at any point, return null
    }
  }

  return currentFolder?.type === 'folder' ? currentFolder : null;
};

export const ROOT_PATH = '/';

export const getRootFolder = (): AssistantFile => ({
  type: 'folder',
  name: 'Root',
  path: ROOT_PATH,
  status: AssistantFileStatus.done,
  progress: 0,
  children: [],
});

const cloneFile = (file: AssistantFile): AssistantFile => {
  return {
    ...file,
    children: file.children.map(cloneFile),
  };
}

interface UploadFileToArgs {
  file: File;
  signedUrl: string;
  headers: { [ key: string ]: string };
  onProgress: (progress: number) => void;
  onError: (error: string) => void;
}

const uploadFileToGCS = ({
  file,
  signedUrl,
  headers,
  onProgress,
  onError,
}: UploadFileToArgs
) => {

  const client = axios.create({
    headers: {
      "Content-type": "application/json",
      ...headers,
    },
  });

  try {
    client.put(signedUrl, file, {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress(percentCompleted);
        }
      }
    });
  } catch (error) {
    console.error(`Error uploading file to GCS: ${error}`);
    onError((error as Error).message);
  }
};

const getDirStatus = (dir: AssistantFile): AssistantFileStatus => {
  let status = AssistantFileStatus.done;

  let hasIndexing = false;
  let hasError = false;
  let hasUploading = false;

  for (const file of dir.children) {
    if (file.type === 'folder') {
      const childStatus = getDirStatus(file);
      if (childStatus === AssistantFileStatus.uploading) {
        hasUploading = true;
      }
      else if (childStatus === AssistantFileStatus.indexing) {
        hasIndexing = true;
      }
      else if (childStatus === AssistantFileStatus.error) {
        hasError = true;
      }
    }
    else if (file.status === AssistantFileStatus.uploading) {
      hasUploading = true;
    }
    else if (file.status === AssistantFileStatus.indexing) {
      hasIndexing = true;
    }
    else if (file.status === AssistantFileStatus.error) {
      hasError = true;
    }
  }

  if (hasUploading) {
    return AssistantFileStatus.uploading;
  }
  if (hasIndexing) {
    return AssistantFileStatus.indexing;
  }
  if (hasError) {
    return AssistantFileStatus.error;
  }

  return status;
}

const hasAnyPendingChildren = (dir: AssistantFile): boolean => {
  for (const file of dir.children) {
    if (file.status === AssistantFileStatus.uploading || file.status === AssistantFileStatus.indexing) {
      return true;
    }
  }

  return false;
}

const setAllParentFoldersStatus = (root: AssistantFile, file: AssistantFile, status: AssistantFileStatus) => {
  const parts = file.path.split('/');
  //remove the file name
  parts.pop();
  while (parts.length > 0) {
    const path = parts.join('/');
    console.log('path', path);
    const parentFolder = findFolderByPath(root, path);
    if (parentFolder) {
      parentFolder.status = status;
    }
    parts.pop();
  }
}