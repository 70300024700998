import { Divider, VStack, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ModelSettings } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import CustomSlider from "../CustomSlider";
import { Model_Parameter } from "../../gen-ts/ai/engine_pb";


interface Props {
  params?: { [ id: string ]: Model_Parameter };
  modelSettings: ModelSettings;
  onUpdate: (modelSettings: ModelSettings) => void;
  disabled: boolean;
}

const ModelSettingsBox: React.FC<Props> = ({ modelSettings, onUpdate, params, disabled }) => {

  const [ data, setData ] = useState<ModelSettings>(modelSettings);


  const maxTokensMin = (params ? params[ 'max_tokens' ]!.minValue!.value!.value : 0) as number;
  const maxTokensMax = (params ? params[ 'max_tokens' ]!.maxValue!.value!.value : 0) as number;

  const temperatureMin = (params ? params[ 'temperature' ]!.minValue!.value!.value : 0) as number;
  const temperatureMax = (params ? params[ 'temperature' ]!.maxValue!.value!.value : 0) as number;


  useEffect(() => {

    if (data.maxTokens && data.maxTokens > maxTokensMax) {
      data.maxTokens = maxTokensMax;
      setData(data.clone());
      onUpdate(data.clone());
    }

    if (data.maxTokens && data.maxTokens < maxTokensMin) {
      data.maxTokens = maxTokensMin;
      setData(data.clone());
      onUpdate(data.clone());
    }

    if (data.temperature && data.temperature > temperatureMax) {
      data.temperature = temperatureMax;
      setData(data.clone());
      onUpdate(data.clone());
    }

    if (data.temperature && data.temperature < temperatureMin) {
      data.temperature = temperatureMin;
      setData(data.clone());
      onUpdate(data.clone());
    }

    //eslint-disable-next-line
  }, [modelSettings, params]);




  

  const isTemperatureDisabled = temperatureMin === temperatureMax;


  return (
    <VStack p={6} bgColor={useColorModeValue('white', 'gray.800')} borderRadius={10}>
      <CustomSlider
        key="max_tokens"
        disabled={disabled}
        label="Max Tokens"
        tooltip="Max Tokens refers to the maximum number of tokens the model can generate or process in a single response or operation. This setting limits the length of output produced by the model, impacting both the detail and scope of responses. Adjust this setting based on the complexity and verbosity required for your tasks. Higher values allow for more extensive outputs, while lower values result in shorter, more concise responses."
        defaultValue={data.maxTokens!}
        min={maxTokensMin}
        max={maxTokensMax}
        step={1}
        onChange={(value) => {
          data.maxTokens = value;
          setData(data.clone());
          onUpdate(data.clone());
        }}
      />
      <Divider my={3} />

      <CustomSlider
        disabled={disabled || isTemperatureDisabled}
        label="Temperature"
        tooltip="Temperature controls the randomness of the model's responses. A higher temperature setting increases the likelihood of generating diverse and less predictable outputs, often leading to more creative or varied responses. Conversely, a lower temperature results in more deterministic and possibly more accurate outputs, aligning closely with the most expected results based on the input data. This setting is crucial when balancing between creativity and precision in the model's performance."
        defaultValue={data.temperature!}
        min={temperatureMin}
        max={temperatureMax}
        step={0.01}
        onChange={(value) => {
          data.temperature = value;
          setData(data.clone());
          onUpdate(data.clone());
        }}
      />
    </VStack>
  );
};

export default ModelSettingsBox;
