import {
  HStack,
  Text,
  VStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorMode,
  Image,
  Flex,
  Badge,
  useColorModeValue,
  Portal
} from "@chakra-ui/react";
import { MdMoreHoriz, MdLogout, MdDarkMode, MdLightMode } from "react-icons/md";

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserState } from "../../state/user";
import { useTenantInfo } from "../../hooks/useTenantInfo";
import { Link } from "react-router-dom";
import { getSkin } from "../../skins";

const AccountWidget: React.FC<{ isInDrawer: boolean }> = ({ isInDrawer }) => {

  var user = useUserState();
  const { logout } = useAuth0();
  const { colorMode, toggleColorMode } = useColorMode();
  const { tenantInfo, isAdmin, isDeveloper } = useTenantInfo();

  const onLogout = () => {
    logout?.();
  }


  return (
    <VStack
      align="space-between"
      borderTopWidth="1px"
      borderTopColor={useColorModeValue('gray.300', isInDrawer ? 'gray.600' : 'gray.700')}
      px={2}
      py={3}
      width="100%"
    >
      <HStack>
        <VStack spacing={0} align="start" flexShrink="1" width="100%" overflow="hidden">
          <Text noOfLines={1} maxWidth="180px" fontSize={17} fontWeight="bold">{user.user?.name}</Text>
          <Text noOfLines={1} maxWidth="180px">{user.user?.email}</Text>
        </VStack>

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<MdMoreHoriz />}
            variant='ghost'
            size="sm"
            borderRadius="100px"
          />
          <Portal>
            <MenuList sx={{ zIndex: 1000 }}>
              <Flex align="center" justify="center" bgColor="gray.50" m={2} borderRadius={10} py={1}>
                <Image src={tenantInfo?.logo} alt="Peak6" width="100px" />
              </Flex>
              <MenuItem onClick={toggleColorMode} icon={colorMode === 'dark' ? <MdDarkMode /> : <MdLightMode />}>
                {colorMode === 'dark' ? 'Light Mode' : 'Dark Mode'}
              </MenuItem>
              {
                !getSkin().assistantId && (
                  <>
                    <MenuItem as={Link} to="/assistants">
                      Assistants
                    </MenuItem>
                    <MenuItem as={Link} to="/help">
                      Help
                    </MenuItem>
                  </>
                )
              }
              <MenuDivider />
              <MenuItem icon={<MdLogout />} onClick={onLogout}>
                Logout
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </HStack>
      <HStack justify="space-evenly">
        {
          isAdmin() && <Badge colorScheme="gray" px={2} py={1} borderRadius={5}>Admin</Badge>
        }
        {
          isDeveloper() && <Badge colorScheme="gray" px={2} py={1} borderRadius={5}>Developer</Badge>
        }

      </HStack>
    </VStack>
  );
};

export default AccountWidget;


