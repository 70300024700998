import { Avatar, Box, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useChats } from "../../../state/chats";
import { getSkin } from "../../../skins";
import MessageContent from "./MessageContent";

import { Message } from "../../../gen-ts/ai/assistants/v0/assistant_pb";
import { customRenderers } from "./CustomRenderers/customRenderers";
import AssistantMessafeActions from "./CustomRenderers/AssistantMessageActions";

interface AssistantMessageProps {
  message: Message;
  streamRunning: boolean;
  onShowSources: (runId: string, filters?: string[]) => void;
  onFeedback: (runId: string) => void;
  assistantId: string;
}

const AssistantMessage: React.FC<AssistantMessageProps> = ({ message, streamRunning, onShowSources, assistantId, onFeedback }) => {
  const chatsState = useChats();


  useEffect(() => {
    if (!streamRunning) {
      chatsState.reloadRun(message.runId);
    }

    //eslint-disable-next-line
  }, [ streamRunning ])


  const CustomRenderer = customRenderers[ assistantId ];


  const run = chatsState.runs[ message.runId ];


  return (
    <HStack
      justify="start"
      alignSelf="start"
      align="start"
      width="100%"
      maxWidth="1200px"
    // mx="auto"
    >
      <Avatar name='System Message' src={getSkin().logo} size="sm" />
      <VStack align="start" sx={{ width: '100%' }}>
        {
          CustomRenderer ? (
            <CustomRenderer
              message={message}
              onFeedback={onFeedback}
              onShowSources={onShowSources}
              streamRunning={streamRunning}
            />
          ) : (
            <Box width="100%" sx={{ display: 'flex', justifyContent: 'start' }}>
              <MessageContent message={message} />
            </Box>
          )
        }

{
          run?.failureReason && (
            <Box>
              <Text color="red">Error: {run.failureReason}</Text>
            </Box>
          )
        }


        <AssistantMessafeActions
          message={message}
          streamRunning={streamRunning}
          assistantId={assistantId}
          onShowSources={onShowSources}
        />

        


      </VStack>
    </HStack>
  );
}


export default AssistantMessage;