import { create } from "zustand"
import { getApiClient } from "../api";
import { Message } from "../gen-ts/ai/assistants/v0/assistant_pb";
import { replaceMessageCitations } from "../utils/replaceMessageCitations";


interface ChildRunsState {
  messages: {
    [ runId: string ]: Message;
  },
  startStreamingChildRun: (childRunId: string) => void;
}


export const useChildRuns = create<ChildRunsState>((set, get) => ({
  messages: {},
  startStreamingChildRun: async (childRunId: string) => {
    const { client, headers } = getApiClient();
    if (get().messages[ childRunId ]) {
      return;
    }

    const stream = client.streamRunResults({ runId: childRunId }, { headers });
    for await (const response of stream) {
      const message = response.message;
      if (message) {
        const existingMessage = get().messages[ childRunId ];
        if (message.equals(existingMessage)) {
          continue;
        }
        set({
          messages: {
            ...get().messages,
            [ childRunId ]: replaceMessageCitations(message),
          },
        });
      }
    }
  },
}));

