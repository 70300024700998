import { IconButton, Text } from "@chakra-ui/react";

import { Avatar, VStack } from "@chakra-ui/react";

import { HStack } from "@chakra-ui/react";

import { Collapse } from "@chakra-ui/react";
import { useFeedback } from "../../../../state/feedback";
import { getSkin } from "../../../../skins";
import { GoThumbsdown } from "react-icons/go";
import { useAppToast } from "../../../../hooks/useAppToast";
import { CreateRunFeedbackRequest_Rating } from "../../../../gen-ts/ai/assistants/v0/assistant_pb";
import { GoThumbsup } from "react-icons/go";
import { PiSmileyMeh } from "react-icons/pi";

interface FeedbackActionsProps {
  runId: string;
  onFeedback: (runId: string) => void;
}

const FeedbackActions: React.FC<FeedbackActionsProps> = ({ runId, onFeedback }) => {
  const toast = useAppToast();
  const feedback = useFeedback();

  const submitFeedback = (rating: CreateRunFeedbackRequest_Rating) => {
    feedback.submitThreadRunFeedback({
      runId,
      rating,
    });

    toast({
      message: 'Feedback submitted.',
      variant: 'success',
    });
  }

  return (
    <Collapse in={feedback.threadRunFeedbacks[ runId ] === undefined}>
      <HStack align="start" sx={{ mt: 2 }}>
        <Avatar name='System Message' src={getSkin().logo} size="xs" />
        <VStack align="start" spacing={0}>
          <Text sx={{ fontSize: 13 }}>How did I do?</Text>
          <HStack spacing={0}>
            <IconButton
              variant="ghost"
              sx={{ borderRadius: 99 }}
              icon={<GoThumbsup />}
              aria-label="Thumbs up"
              onClick={() => {
                submitFeedback(CreateRunFeedbackRequest_Rating.POSITIVE);
              }}
            />
            <IconButton
              variant="ghost"
              sx={{ borderRadius: 99 }}
              icon={<PiSmileyMeh />}
              aria-label="Straight face"
              onClick={() => {
                submitFeedback(CreateRunFeedbackRequest_Rating.NEUTRAL);
              }}
            />
            <IconButton
              variant="ghost"
              sx={{ borderRadius: 99 }}
              icon={<GoThumbsdown />}
              aria-label="Thumbs down"
              onClick={() => {
                onFeedback(runId);
              }}
            />
          </HStack>
        </VStack>
      </HStack>
    </Collapse>
  )
}


export default FeedbackActions;