import { create } from "zustand"
import { ChatFile, ChatInfo, ChatMessageFile, StreamResultResponse } from "../types";
import { Timestamp } from "@bufbuild/protobuf";
import { getApiClient, getChatFilesApi } from "../api";
import {
  CreateMessageRequest,
  CreateMessageRequest_Attachment,
  CreateRunRequest,
  CreateThreadAndRunRequest,
  CreateThreadRequest,
  DeleteThreadRequest,
  GetRunRequest,
  ListMessagesRequest,
  ListThreadsRequest,
  MessageContent,
  Role,
  Run,
  ShareThreadRequest,
  SortOrder,
  Thread,
  UpdateThreadRequest,
} from "../gen-ts/ai/assistants/v0/assistant_pb";
import { useAssistants } from "./assistants";
import { Message } from "../gen-ts/ai/assistants/v0/assistant_pb";
import { sortBy } from "lodash";
import { replaceMessageCitations, replaceMessageListCitations } from "../utils/replaceMessageCitations";





interface ChatsStat {
  threads: Thread[];
  chats: {
    [ threadId: string ]: ChatInfo;
  };
  runs: {
    [ runId: string ]: Run;
  },
  loadingList: boolean;
  loadingById: boolean;
  nextPageToken: string | null;
  nextPageTokenLegacy: string | null;
  newChatPending: boolean;
  initialListLoaded: boolean;
  uiListLoaded: boolean;
  loadChats: () => void;
  loadChatByThreadId: (threadId: string, isShared: boolean) => void;
  newChat: (args: { assistantId: string, message: string, files: ChatFile[], messageHistory?: Message[] }) => Promise<string | null>;
  newMessage: (args: { threadId: string, assistantId: string, message: string, files: ChatFile[] }) => Promise<string | null>;
  startStreaming: (args: { threadId: string }) => void;
  loadRunById: (runId: string) => Promise<Run | null>;
  runLoading: {
    [ runId: string ]: boolean;
  },
  fileLoading: {
    [ fileRef: string ]: boolean;
  }
  files: {
    [ fileRef: string ]: ChatMessageFile;
  },
  loadFile: (fileRef: string) => void;
  deleteChat: (threadId: string) => void;
  renameChat: (threadId: string, newName: string) => void;
  createShareableLink: (threadId: string) => Promise<string | null>;
  reloadRun: (runId: string) => Promise<Run | null>;
}

export const useChats = create<ChatsStat>((set, get) => ({
  threads: [],
  chats: {},
  runs: {},
  loadingList: false,
  initialListLoaded: false,
  uiListLoaded: false,
  loadingById: false,
  nextPageToken: null,
  nextPageTokenLegacy: null,
  newChatPending: false,
  runLoading: {},
  fileLoading: {},
  files: {},
  newChat: async ({ assistantId, message, files, messageHistory }) => {
    try {
      const assistant = useAssistants.getState().assistants[ assistantId ];
      if (!assistant) {
        return null;
      }

      const attachments = files.filter((file) => Boolean(file.ref)).map((file) => {
        return {
          content: {
            case: 'file',
            value: {
              source: {
                case: 'ref',
                value: file.ref!,
              }
            },
          }
        };
      }) as CreateMessageRequest_Attachment[];

      const initialMessage = new CreateMessageRequest({
        content: message,
        role: Role.USER,
        attachments,
      });

      const createThreadRequest = new CreateThreadRequest({
        messages: [
          ...(messageHistory || []).map((msg) => {
            let msgText = '';
            for (const content of msg.content) {
              if (content.content.case === 'text') {
                msgText = content.content.value;
                break;
              }
            }
            const attachments: CreateMessageRequest_Attachment[] = [];
            for (const content of msg.content) {
              if (content.content.case === 'file') {
                attachments.push(content as CreateMessageRequest_Attachment);
              }
            }
            return new CreateMessageRequest({
              content: msgText,
              role: msg.role,
              attachments,
            });
          }),
          initialMessage,
        ],
      });



      const createThreadAndRunRequest = new CreateThreadAndRunRequest({
        thread: createThreadRequest,
        assistantId: assistant.id,
      });

      set({
        newChatPending: true,
      });

      const { client, headers } = getApiClient();

      const response = await client.createThreadAndRun(
        createThreadAndRunRequest,
        { headers }
      );

      const userMessage = new Message({
        id: crypto.randomUUID(),
        content: [
          new MessageContent({
            content: { case: 'text', value: message },
          }),
        ],
        role: Role.USER,
        runId: response.id,
        createTime: Timestamp.now(),
      });

      // const emptyAiMessage      

      const chat: ChatInfo = {
        threadId: response.threadId,
        createTime: response.createTime!,
        assistantId: assistant.id,
        messages: [
          ...(messageHistory || []),
          userMessage,
        ],
      };

      const thread = new Thread({
        id: response.threadId,
        description: message.slice(0, 50),
        createTime: response.createTime!,
      });


      const streamResult = new StreamResultResponse(response.id, response.threadId);
      chat.streamingResponse = streamResult;

      set({
        newChatPending: false,
        threads: [
          thread,
          ...get().threads,
        ],
        chats: {
          ...get().chats,
          [ response.threadId ]: chat,
        },
      });


      return response.threadId;
    }
    catch (e) {
      console.error(e);
      return null;
    }
  },
  newMessage: async ({ threadId, assistantId, message, files }) => {

    try {
      const assistant = useAssistants.getState().assistants[ assistantId ];
      const chat = get().chats[ threadId ];
      if (!assistant || !chat) {
        return null;
      }

      // const fileInfos: {
      //   [ ref: string ]: ChatMessageFile;
      // } = {};

      const attachments = files.filter((file) => Boolean(file.ref)).map((file) => {
        // fileInfos[ file.ref! ] = {
        //   contentType: file.contentType,
        //   fileName: file.fileName,
        //   image: file.url,
        //   ref: file.ref,
        //   fetchTime: Date.now(),
        // }
        return {
          content: {
            case: 'file',
            value: {
              source: {
                case: 'ref',
                value: file.ref!,
              }
            },
          }
        };
      }) as CreateMessageRequest_Attachment[];

      const newMessageRequest = new CreateMessageRequest({
        content: message,
        role: Role.USER,
        attachments,
      });

      const createRunRequest = new CreateRunRequest({
        threadId: threadId,
        assistantId: assistant.id,
        additionalInstructions: "",
        additionalMessages: [ newMessageRequest ],
      });

      const { client, headers } = getApiClient();


      const userMessage = new Message({
        id: crypto.randomUUID(),
        content: [
          new MessageContent({
            content: { case: 'text', value: message },
          }),
          ...attachments.map((attachment) => {
            return new MessageContent({
              content: attachment.content,
            });
          }),
        ],
        role: Role.USER,
        runId: crypto.randomUUID(),
        createTime: Timestamp.now(),
      });

      const tmpUpdatedChat = { ...chat };
      tmpUpdatedChat.messages.push(userMessage);
      tmpUpdatedChat.streamStartPending = true;
      set({
        chats: {
          ...get().chats,
          [ threadId ]: tmpUpdatedChat,
        },
      });

      const runResponse = await client.createRun(createRunRequest, {
        headers,
      });

      const updatedChat = { ...chat };

      const streamResult = new StreamResultResponse(runResponse.id, runResponse.threadId);
      updatedChat.streamingResponse = streamResult;
      updatedChat.streamStartPending = false;

      set({
        chats: {
          ...get().chats,
          [ threadId ]: updatedChat,
        },
      });

      return runResponse.id;
    }
    catch (e) {
      console.error(e);
      return null;
    }
  },
  startStreaming: async ({ threadId }) => {
    try {
      const chat = get().chats[ threadId ];
      if (!chat || !chat.streamingResponse) {
        return null;
      }

      const { client, headers } = getApiClient();

      const runId = chat.streamingResponse.runId;
      const streamRequest = { runId };


      const stream = client.streamRunResults(streamRequest, { headers });

      const updateLastMessage = (message: Message): ChatInfo => {
        let updatedChat = { ...chat };

        const msgList = [ ...updatedChat.messages ];

        if (msgList.length === 0) {
          return updatedChat;
        }

        if (msgList[ msgList.length - 1 ].role === Role.ASSISTANT) {
          msgList[ msgList.length - 1 ] = message;
          updatedChat = {
            ...updatedChat,
            messages: msgList,
          };
        }
        else {

          updatedChat.messages.push(message);
        }

        return updatedChat;
      }

      //insert empty message
      set({
        chats: {
          ...get().chats,
          [ threadId ]: createEmptyMessage(runId, chat),
        },
      });



      try {
        for await (const response of stream) {

          if (get().chats[ threadId ].streamingResponse?.isCanceled()) {
            break;
          }
  
          if (response.message?.content) {
            set({
              chats: {
                ...get().chats,
                [ threadId ]: updateLastMessage(replaceMessageCitations(response.message)),
              },
            });
          } else {
            console.warn("Received response with no content:", response);
          }
        }
      }
      catch(e) {
        const run = await get().reloadRun(runId);
        if (run) {
          set({
            runs: {
              ...get().runs,
              [ runId ]: run,
            },
          });
        }
        console.error('streamRunResults', run?.failureReason);
      }

      

      const updatedChat = get().chats[ threadId ];
      updatedChat.streamingResponse = undefined;
      set({
        chats: {
          ...get().chats,
          [ threadId ]: updatedChat,
        }
      });

    }
    catch (e) {
      console.error(e);
    }

  },
  loadChatByThreadId: async (threadId: string) => {
    const chat = get().chats[ threadId ];

    if (get().loadingById || chat?.messages.length > 0) {
      return;
    }

    set({ loadingById: true });

    try {
      const { client, headers } = getApiClient();

      const listMessagesReq = new ListMessagesRequest({
        threadId,
        pageSize: 100,
      });

      const listMessagesResp = await client.listMessages(listMessagesReq, { headers });

      const runId = listMessagesResp.messages[ listMessagesResp.messages.length - 1 ].runId;

      let asistantId = useAssistants.getState().selectedAssistant;
      if (Boolean(runId)) {
        const getRunReq = new GetRunRequest({ id: runId });
        const run = await client.getRun(getRunReq, { headers });



        set({
          runs: {
            ...get().runs,
            [ runId ]: run,
          },
        });
        asistantId = run.assistantId;

        // console.log('run.childRunIds', run.childRunIds);
        // const stream = client.streamRunResults({ runId: run.childRunIds[ 0 ] }, { headers });
        // for await (const response of stream) {
        //   console.log('response', response);
        // }
      }

      const chat: ChatInfo = {
        threadId,
        createTime: listMessagesResp.messages[ 0 ].createTime!,
        assistantId: asistantId,
        messages: replaceMessageListCitations(combineMessagesWithSameRunId(listMessagesResp.messages)),
        // messages: listMessagesResp.messages,
      };

      set({
        loadingById: false,
        chats: {
          ...get().chats,
          [ threadId ]: chat,
        },
      });
    }
    catch (e) {
      console.error(e);
      set({ loadingById: false });
    }
  },
  loadChats: async () => {
    if (get().loadingList || (!get().nextPageToken && !get().nextPageTokenLegacy && get().initialListLoaded)) {
      return;
    }

    set({ loadingList: true });

    const pageSize = 100;
    try {
      let legacyResult: ListThreadsResult | null = null;
      let result: ListThreadsResult | null = null;

      if (!get().uiListLoaded) {
        result = await listThreads({ pageSize, pageToken: get().nextPageToken, origin: 'ui' });
      }

      //finished loading UI list, now try to load LEGACY list
      if ((result?.threads.length ?? 0) < pageSize) {
        set({ uiListLoaded: true });
        legacyResult = await listThreads({ pageSize, pageToken: get().nextPageTokenLegacy, origin: 'legacy' });
      }

      set({
        initialListLoaded: true,
        loadingList: false,
        threads: [
          ...get().threads,
          ...result?.threads || [],
          ...legacyResult?.threads || [],
        ],
        nextPageToken: result?.nextPageToken || null,
        nextPageTokenLegacy: legacyResult?.nextPageToken || null,
      });
    }
    catch (e) {
      console.error('loadChats', e);
      set({ loadingList: false });
    }
  },
  loadRunById: async (runId: string): Promise<Run | null> => {
    if (get().runLoading[ runId ]) {
      return null;
    }

    if (get().runs[ runId ]) {
      return get().runs[ runId ];
    }

    try {
      set({
        runLoading: {
          ...get().runLoading,
          [ runId ]: true,
        },
      });
      const { client, headers } = getApiClient();

      const getRunReq = new GetRunRequest({ id: runId });
      const run = await client.getRun(getRunReq, { headers });

      set({
        runs: {
          ...get().runs,
          [ runId ]: run,
        },
        runLoading: {
          ...get().runLoading,
          [ runId ]: false,
        },
      });

      return run;
    }
    catch (e) {
      console.error(e);
      set({
        runLoading: {
          ...get().runLoading,
          [ runId ]: false,
        },
      });
    }

    return null;
  },
  loadFile: async (fileRef: string) => {
    if (get().fileLoading[ fileRef ]) {
      return;
    }

    const file = get().files[ fileRef ];
    // if last fetched within 20 minutes ago, skip fetching
    if (file && Date.now() - file.fetchTime <= 20 * 60 * 1000) {
      return;
    }

    try {
      set({
        fileLoading: {
          ...get().fileLoading,
          [ fileRef ]: true,
        },
      });

      const { client, headers } = getChatFilesApi();

      console.log('load file..', fileRef);

      const resp = await client.get(fileRef, { headers });
      const file: ChatMessageFile = {
        contentType: resp.data.contentType,
        fileName: resp.data.filename,
        url: resp.data.publicDownloadUrl,
        ref: resp.data.self,
        fetchTime: Date.now(),
      };


      set({
        fileLoading: {
          ...get().fileLoading,
          [ fileRef ]: false,
        },
        files: {
          ...get().files,
          [ fileRef ]: file,
        }
      });
    }
    catch (e) {
      console.error(e);
      set({
        fileLoading: {
          ...get().fileLoading,
          [ fileRef ]: false,
        },
      });
    }
  },
  deleteChat: async (threadId: string) => {
    const { client, headers } = getApiClient();

    try {
      client.deleteThread(new DeleteThreadRequest({ id: threadId }), { headers });

      const updatedThreads = get().threads.filter((thread) => thread.id !== threadId);
      const updatedChats = { ...get().chats };
      delete updatedChats[ threadId ];

      set({
        threads: updatedThreads,
        chats: updatedChats,
      });
    }
    catch (e) {
      console.error(e);
    }
  },
  renameChat: async (threadId: string, newName: string) => {
    const threadIndex = get().threads.findIndex((thread) => thread.id === threadId);
    if (threadIndex === -1) {
      return;
    }

    const { client, headers } = getApiClient();

    try {

      const updatedThread = get().threads[ threadIndex ].clone();
      updatedThread.description = newName;


      client.updateThread(new UpdateThreadRequest(updatedThread), { headers });

      const updatedThreads = [ ...get().threads ];
      updatedThreads[ threadIndex ] = updatedThread;

      set({
        threads: updatedThreads,
      });
    }
    catch (e) {
      console.error(e);
    }
  },
  createShareableLink: async (threadId: string) => {
    const { client, headers } = getApiClient();

    try {
      const request = new ShareThreadRequest({ id: threadId });
      const result = await client.shareThread(request, { headers });
      return result.id;
    }
    catch (e) {
      console.error('createShareableLink', e);
      return null;
    }
  },
  reloadRun: async (runId: string) => {
    const { client, headers } = getApiClient();
    const getRunReq = new GetRunRequest({ id: runId });
    const run = await client.getRun(getRunReq, { headers });

    set({
      runs: {
        ...get().runs,
        [ runId ]: run,
      },
    });

    return run;
  }
}));


interface ListThreadsResult {
  threads: Thread[];
  nextPageToken: string | null;

}

const listThreads = async (args: { pageSize: number, pageToken: string | null, origin: 'ui' | 'legacy' }) => {
  const { client, headers } = getApiClient();

  const result: ListThreadsResult = {
    threads: [],
    nextPageToken: null,
  }

  try {
    let currPageToken = args.pageToken;
    while (result.threads.length < args.pageSize) {
      const listThreadsReq = new ListThreadsRequest({
        order: SortOrder.DESCENDING,
        pageSize: args.pageSize,
        pageToken: currPageToken || undefined,
        origin: args.origin
      });
      const listThreadsResp = await client.listThreads(listThreadsReq, { headers });
      const batchThreads = listThreadsResp.threads.filter((thread) => Boolean(thread.description));
      result.nextPageToken = listThreadsResp.nextPageToken;
      currPageToken = listThreadsResp.nextPageToken;
      result.threads.push(...batchThreads);
      if (!listThreadsResp.nextPageToken) {
        break;
      }
    }
  }
  catch (e) {
    console.error('listThreads', e);
  }

  return result;
}



const combineMessagesWithSameRunId = (messages: Message[]): Message[] => {
  const runMessages: Record<string, Message[]> = {};

  for (const message of messages) {
    if (!message.runId) {
      continue;
    }

    if (!runMessages[ message.runId ]) {
      runMessages[ message.runId ] = [
        message,
      ];
    }
    else {
      runMessages[ message.runId ].push(message);
    }
  }

  for (const runId in runMessages) {
    //sort messages by createTime
    runMessages[ runId ] = sortBy(runMessages[ runId ], (msg) => msg.createTime?.toDate().getTime());
  }


  const result: Message[] = [];
  let clonedMessages = [ ...messages ];
  while (clonedMessages.length > 0) {
    const msg = clonedMessages.shift();
    if (!msg) {
      continue;
    }

    if (!msg.runId || !runMessages[ msg.runId ]) {
      result.push(msg);
      continue;
    }

    if (runMessages[ msg.runId ].length === 1) {
      result.push(runMessages[ msg.runId ][ 0 ]);
      delete runMessages[ msg.runId ];
      continue;
    }

    const combinedMessage = runMessages[ msg.runId ].shift();
    for (const item of runMessages[ msg.runId ]) {
      combinedMessage!.content.push(...item.content);
    }
    result.push(combinedMessage!);
    delete runMessages[ msg.runId ];


    //delete all messages with that runId
    clonedMessages = clonedMessages.filter((msg) => msg.runId !== combinedMessage!.runId);
  }


  return result;
}


const createEmptyMessage = (runId: string, chat: ChatInfo): ChatInfo => {
  const newMessage = new Message({
    id: crypto.randomUUID(),
    content: [
      new MessageContent({
        content: { case: 'text', value: '' },
      }),
    ],
    role: Role.ASSISTANT,
    runId: runId,
    createTime: Timestamp.now(),
  });

  const updatedChat: ChatInfo = {
    ...chat,
    messages: [
      ...chat.messages,
      newMessage,
    ],
  };
  return updatedChat;
}